<template>
     <div class="my_bid">
          <div class="title">投标邀请</div>

          <div class="nav_bar">
               <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="待投标" name="20"></el-tab-pane>
                    <el-tab-pane label="已结束" name="30"></el-tab-pane>
                    <el-tab-pane label="已终止" name="40"></el-tab-pane>
               </el-tabs>
          </div>

          <div class="box1" v-if="bidList.length">
               <invite_card v-for="(item,index) in bidList" :key="index" :info="item" @delSuccess="delSuccess">
               </invite_card>
               <!-- 分页 -->
               <div class="flex_row_center_center sld_pagination">
                    <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                         :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                         :hide-on-single-page="true">
                    </el-pagination>
               </div>
          </div>
          <div class="bid_empty flex_column_center_center" v-else>
               <img src="@/assets/inquiry/inq_empty.png" alt="">
               <p>暂无投标邀请</p>
          </div>

     </div>
</template>

<script>
     import { ref, reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
     import { inqUtils } from '@/utils/inquiry_utils.js'
     import { useRouter } from 'vue-router'
     import { ElMessage } from 'element-plus'
     import invite_card from '@/views/bid/component/invite_card'
     export default {
          components: {
               invite_card
          },
          setup(props) {
               const activeName = ref('20')
               const { formatTime, hp } = inqUtils()
               const { proxy } = getCurrentInstance()

               const param = reactive({
                    current: 1,
                    state: activeName.value,
                    pageSize: 5
               })

               const router = useRouter()
               const invite_visible = ref(false)
               const bidList = ref([])

               const page = reactive({
                    current: 1,
                    pageSize: 10,
                    total: 20
               })


               const getList = () => {
                    proxy.$get('v3/business/front/enterBid/inviteList', param).then(res => {
                         if (res.state == 200) {
                              bidList.value = res.data.list
                              page.current = res.data.pagination.current
                              page.pageSize = res.data.pagination.pageSize
                              page.total = res.data.pagination.total
                              console.log(page, 'pages')
                         }
                    })
               }

               const toDelete = (inquireId) => {
                    proxy.$confirm('确认删除该投标？', {
                         confirmButtonText: '确定',
                         cancelButtonText: '取消',
                         type: 'warning',
                         center: true
                    }).then(res => {
                         proxy.$post('v3/business/front/purchaseInquire/delete', { inquireId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   getList()
                              }
                         })
                    })
               }

               const handleClick = (tab) => {
                    param.current = 1
                    param.state = tab.props.name
                    getList()
               }

               const handleCurrentChange = (e) => {
                    param.current = e
                    getList()
               }

               const delSuccess = () => {
                    getList()
               }

               onMounted(() => {
                    getList()
               })

               return {
                    activeName,
                    handleClick,
                    page,
                    bidList,
                    handleCurrentChange,
                    delSuccess
               }
          }
     }


</script>

<style lang="scss">
     input {
          height: 28px;
          outline: none;
          border: none;

     }




     .my_bid {
          .is-active {
               color: $colorMain;
          }

          .el-tabs__active-bar {
               background-color: $colorMain;
          }

          .el-tabs__item:hover {
               color: $colorMain;
               cursor: pointer;
          }

          .el-date-editor--daterange.el-input__inner {
               width: 250px;
          }

          .el-date-editor .el-range-separator {
               width: 7%;
          }

          .wrap6 .el-input__inner {
               border: none;
          }

          .has-gutter tr th {
               background: #F7F8FA;
          }
     }
</style>

<style lang="scss" scoped>
     .my_bid {
          float: left;
          width: 1015px;
          background-color: #fff;
          margin-left: 15px;
          padding: 20px;
          position: relative;

          .title {
               font-size: 16px;
               font-family: SourceHanSansCN-Medium, SourceHanSansCN;
               font-weight: 500;
               color: #333333;
               font-weight: bold;
          }

          .nav_bar {
               margin-top: 20px;
          }


          .box1 {
               margin-top: 20px;

               .info1 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
               }

               .wrap3 {
                    background-color: rgba(255, 255, 255, 1);
                    width: 220px;
                    height: 30px;
                    background: #FFFFFF;
                    border: 1px solid #C9C9C9;
                    border-radius: 3px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    /* margin-left: 10px; */
                    padding: 0 10px;
               }

               .word3 {
                    white-space: nowrap;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    margin: 0px 0 0 22px;
               }

               .wrap4 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 15px;
                    width: 115px;

                    .bd3 {
                         width: 95px;
                         height: 18px;
                         margin: 8px 0 0 10px;

                         .word4 {
                              width: 49px;
                              height: 12px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              margin-top: 4px;
                              display: block;
                         }

                         .icon1 {
                              width: 18px;
                              height: 18px;
                         }
                    }
               }

               .word5 {
                    width: 8px;

                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular;
                    text-align: left;
                    white-space: nowrap;
                    line-height: 14px;
                    display: block;
                    margin: 17px 0 0 19px;
               }

               .wrap5 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    margin-left: 17px;
                    width: 115px;

                    .section1 {
                         width: 96px;
                         height: 18px;
                         margin: 9px 0 0 11px;

                         .info2 {
                              width: 49px;
                              height: 12px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              margin-top: 2px;
                              display: block;
                         }

                         .label3 {
                              width: 18px;
                              height: 18px;
                         }
                    }
               }

               .txt1 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    display: block;
                    margin: 0px 0 0 28px;
               }

               .wrap6 {
                    background-color: rgba(255, 255, 255, 1);
                    height: 36px;
                    border: 1px solid rgba(201, 201, 201, 1);
                    width: 100px;
                    margin: 0px 0 0 11px;

                    .layer3 {
                         width: 85px;
                         height: 11px;
                         margin: 12px 0 0 8px;

                         .txt2 {
                              width: 37px;
                              height: 11px;

                              color: rgba(153, 153, 153, 1);
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 12px;
                              display: block;
                         }

                         .label4 {
                              width: 13px;
                              height: 7px;
                              margin-top: 3px;
                         }
                    }
               }

               .wrap7 {
                    background-color: rgba(40, 95, 222, 1);
                    border-radius: 4px;
                    height: 32px;
                    width: 68px;
                    margin: 3px 0 0 31px;
                    cursor: pointer;

                    .info3 {
                         width: 28px;
                         height: 13px;

                         color: rgba(255, 255, 255, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }

               .wrap8 {
                    border-radius: 4px;
                    height: 32px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 68px;
                    margin: 3px 0 0 20px;
                    cursor: pointer;

                    .info4 {
                         width: 29px;
                         height: 13px;

                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         text-align: left;
                         white-space: nowrap;
                         line-height: 14px;
                         display: block;
                         margin: 9px 0 0 19px;
                    }
               }
          }



          .bid_empty {
               padding-top: 140px;
               padding-bottom: 200px;

               img {
                    width: 120px;
                    height: 119px;
               }

               p {
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 34px;
               }
          }

     }
</style>