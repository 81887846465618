<template>
     <div class="invite_card">
          <div class="header flex_row_between_center">
               <div class="header_title" @click="toDetail">{{info.inviterCompanyName}}的招标邀请</div>
               <div class="flex_row_start_center header_right">
                    <span>{{info.createTime}}</span>
                    <span class="iconfont iconbutongguo2" @click="goDel"></span>
               </div>
          </div>

          <div class="content">
               <div class="outer2 flex-row">
                    <div class="bd3 flex_row_start_center">
                         <div class="flex_row_end_center bd31">
                              <span class="note_info2">招标主题：</span>
                         </div>
                         <div class="c959">{{info.title}}</div>
                    </div>
               </div>
               <div class="outer2 flex-row">
                    <div class="bd3 flex_row_start_center">
                         <div class="flex_row_end_center bd31">
                              <span class="note_info2">招标编号：</span>
                         </div>
                         <div class="c959">{{info.code}}</div>

                    </div>
               </div>
               <div class="outer2 flex-row">
                    <div class="bd3 flex_row_start_center">
                         <div class="flex_row_end_center bd31">
                              <span class="note_info2">招标类型：</span>
                         </div>
                         <div class="c959">{{info.typeValue}}</div>

                    </div>
               </div>
               <div class="outer2 flex-row">
                    <div class="bd3 flex_row_start_center">
                         <div class="flex_row_end_center bd31">
                              <span class="note_info2">招标单位：</span>
                         </div>
                         <div class="c959">{{info.uintName}}</div>
                    </div>
               </div>
               <div class="outer2 flex-row">
                    <div class="bd3 flex_row_start_center">
                         <div class="flex_row_end_center bd31">
                              <span class="note_info2">投标截止时间：</span>
                         </div>
                         <div class="c959">{{info.deadline}}</div>
                         <div class="flex_row_start_center">
                              <div class="flex_row_end_center bd31">
                                   <span class="note_info2">开标时间：</span>
                              </div>
                              <div class="c959">{{info.openTime}}</div>
                         </div>
                    </div>
               </div>
          </div>

     </div>
</template>

<script>
     import { ref, reactive, getCurrentInstance, toRefs, onMounted } from 'vue'
     import { useRouter, useRoute } from 'vue-router'
     import { ElMessage } from 'element-plus'
     export default {
          props: ['info'],
          setup(props, { emit }) {
               const { proxy } = getCurrentInstance()
               const router = useRouter()
               const route = useRoute()
               const goDel = () => {
                    proxy.$confirm('是否确认删除该条招标信息？', {
                         confirmButtonText: '确定',
                         cancelButtonText: '取消',
                         type: 'warning',
                         center: true
                    }).then(res => {
                         proxy.$post('v3/business/front/enterBid/delete', { bidId: props.info.bidId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   emit('delSuccess')
                              }
                         })
                    })
               }


               const toDetail = () => {
                    let newWin = router.resolve({
                         path: '/bid/detail',
                         query: {
                              bidId: props.info.bidId
                         }
                    })

                    window.open(newWin.href)
               }

               return {
                    goDel,
                    toDetail
               }
          }
     }
</script>


<style lang="scss">
     .invite_card {
          border: 1px solid #E7E7E7;
          border-radius: 10px;
          margin-bottom: 30px;

          .header {
               height: 50px;
               background: #F2F2F2;
               border-radius: 10px 10px 0px 0px;
               padding: 0 20px;
               border-bottom: 1px solid #E7E7E7;

               .header_title {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    cursor: pointer;

                    &:hover {
                         text-decoration: underline;
                    }
               }

               .header_right {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;

                    .iconbutongguo2 {
                         color: #ADADAD;
                         margin-left: 5px;
                         margin-bottom: 2px;
                         cursor: pointer;
                    }
               }
          }

          .content {
               padding: 20px 0;

               .outer2 {
                    width: 100%;
                    margin-bottom: 20px;

                    .bd3 {

                         .bd31 {
                              /* margin-top: 10px; */
                              margin-right: 12px;
                              width: 113px;
                              text-align: right;

                         }

                         .word2 {
                              color: rgba(240, 2, 2, 1);
                              font-size: 14px;
                              line-height: 14px;
                              margin-right: 4px;
                         }

                         .note_info2 {
                              color: #95989C;
                              font-size: 14px;
                              line-height: 14px;
                              white-space: nowrap;
                         }

                         .c959 {
                              font-size: 14px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                              color: #333;
                         }

                         .outer15 {
                              border-radius: 4px;
                              height: 35px;
                              border: 1px dashed rgba(40, 95, 222, 1);
                              width: 150px;
                              cursor: pointer;

                              .info10 {
                                   height: 14px;
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   display: block;
                                   line-height: 35px;
                                   text-align: center;
                              }
                         }
                    }

               }
          }
     }
</style>